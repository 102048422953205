import senseImg from '../images/icons/sense.png';
import replantImg from '../images/icons/replant.png';
import pestImg from '../images/icons/pest.png';
import nutrientImg from '../images/icons/nutrient.png';
import tradeImg from '../images/icons/trade.png';
import smartGraderImg from '../images/icons/smartGrader.png';

import senseBeforeImg from '../images/products/sense/before.png';
import senseafterImg from '../images/products/sense/after.png';
import sensesecbeforeImg from '../images/products/sense/sectionbefore.png';
import sensesecafterImg from '../images/products/sense/sectionafter.png';
import sensetreeLegendImg from '../images/products/sense/tree_legend.png';
import senseplantingLineLegendImg from '../images/products/sense/planting_lines_legend.png';

import replantBeforeImg from '../images/products/replant/before.png';
import replantafterImg from '../images/products/replant/after.png';
import replantsecbeforeImg from '../images/products/replant/sectionbefore.png';
import replantsecafterImg from '../images/products/replant/sectionafter.png';

import nutrientBeforeImg from '../images/products/nutrient/before.png';
import nutrientafterImg from '../images/products/nutrient/after.png';
import nutrientsecbeforeImg from '../images/products/nutrient/sectionbefore.png';
import nutrientsecafterImg from '../images/products/nutrient/sectionafter.png';

import pestdiseaseBeforeImg from '../images/products/pestdisease/before.png';
import pestdiseaseafterImg from '../images/products/pestdisease/after.png';
import pestdiseasesecbeforeImg from '../images/products/pestdisease/sectionbefore.png';
import pestdiseasesecafterImg from '../images/products/pestdisease/sectionafter.png';

import tradeBeforeImg from '../images/products/trade/before.png';
import tradeafterImg from '../images/products/trade/after.png';
import tradesecbeforeImg from '../images/products/trade/sectionbefore.png';
import tradesecafterImg from '../images/products/trade/sectionafter.png';
import smartGraderImgProcessed from '../images/products/smartgrader/smartgrader.png';
import nutrientSugarCaneImg from '../images/products/nutrient/nutrient_sugarcane.png';

export default {
  name: "Vulcan Ceres",
  ceres_header:
    "Unlock the power of Artificial Intelligence to ensure yield improvements while lowering cost for your crops",
  short_desc:
    "A suite of Artificial Intelligence applications & services ​for improving sustainable yield and health.",
  ceres_benefits: [
    { desc: "Boost Yield", benefit: "5 to 10%" },
    { desc: "Fertilizer Cost Reduction", benefit: "5 to 10%" },
    { desc: "Labour Savings", benefit: "10 to 20%" },
    { desc: "Time Savings", benefit: ">80%" },
  ],
  long_desc_1:
    "Vulcan Ceres is a suite of artificial intelligence (AI) applications & services helping farmers translate data into actionable insights, and make better decisions for yield and quality optimisation at a lower cost.",
  long_desc_2:
    " Ceres uses a multi-sensory approach to generate intelligence for micro-supervision. A combination of satellite, unmanned aerial vehicle (UAV), internet of things (IoT) data and your enterprise data is securely ingested through Vulcan’s AI pipelines to generate actionable insights.",

  products: [
    {
      name: "sense",
      displayName: "Sense+",
      desc:
        "Sense+ helps you to maintain a reliable inventory and land-use assessment. Uses AI models to accurately identify and tag each tree by processing UAV images, satellite images, and geospatial data.",
      img: senseImg,
      detailed_desc:
        "Sense+ uses advanced AI models to process UAV imagery, satellite imagery and geospatial data, to accurately identify and tag every unique tree. Sense+ module offers an effective, low cost method to maintain an up-to-date accurate inventory and evaluate land utilization.",
      case_study: ">98% Accuracy in Palm Tree Detection",
      before_img: senseBeforeImg,
      after_img: senseafterImg,
      before_section: sensesecbeforeImg,
      after_section: sensesecafterImg,
      legends: [
        {
          img: sensetreeLegendImg,
          title: "Tree",
          id: 1,
        },
        {
          img: senseplantingLineLegendImg,
          title: "Planting Lines",
          id: 2,
        },
      ],
      key_outputs: [
        {
          top: "Tree Detection Accuracy",
          benefit: ">98%",
          bottom: "Using Sense+ AI",
          class: "col-md-6",
        },
        {
          top: "Tree Detection Accuracy",
          benefit: "100%",
          bottom: "Using Sense+ AI & Vulcan Correction",
          class: "col-md-6",
        },
      ],
    },
    {
      name: "replant",
      displayName: "Replant+",
      desc:
        "An AI powered automated solution that identifies the blank spots by analyzing UAV and satellite images.  The solution also provides recommendations for the precise number and location of infills.",
      img: replantImg,
      detailed_desc:
        "Mortality is a major concern in the early stages of crop growth, requiring extensive manpower to monitor and remedy blank spot appearances. Ceres Replant+ module can help you to identify blank spots quickly by processing UAV and satellite images efficiently. With addition of customized agronomy rules, Replant+ can also forecast an accurate estimate of infills required, which can help in preparation of sufficient supply stock.",
      case_study: "Improve palm plantation land utilization by as much as 10%",
      before_img: replantBeforeImg,
      after_img: replantafterImg,
      before_section: replantsecbeforeImg,
      after_section: replantsecafterImg,

      key_outputs: [
        {
          top: "Remedy Blankspots",
          benefit: "1 to 2",
          bottom: "Per Hectare",
          class: "col-md-4",
        },
        {
          top: "Extra Trees",
          benefit: "4 to 5",
          bottom: "Per Hectare",
          class: "col-md-4",
        },
        {
          top: "Yield",
          benefit: "2 to 3%",
          bottom: "Improvement",
          class: "col-md-4",
        },
      ],
    },
    {
      name: "health",
      displayName: "Health+",
      desc:
        "A robust solution for the detection of diseases and damaged areas in plantations gives you a better chance of saving your crops. App uses a mixture of algorithms that use 100+ predictive features extracted from multi-spectral satellite and/or drone images.",
      img: pestImg,
      detailed_desc:
        "Pest & Disease is a major concern requiring extensive manpower to monitor and remedy various incidence of Pest & Disease. Ceres Health+ module can help you to identify spots affected by Pest & Disease quickly by processing UAV and satellite images efficiently using AI. The AI uses 100+ features extracted from multi-spectral satellite and drone images to detect areas that could be affected by diseases like Ganoderma, Leaf-eating Caterpillar, Yellowing, Wilt etc.",
      case_study: "Ganoderma disease detection with tree locations.",
      before_img: pestdiseaseBeforeImg,
      after_img: pestdiseaseafterImg,
      before_section: pestdiseasesecbeforeImg,
      after_section: pestdiseasesecafterImg,
      // legends: [
      //   {
      //     title: 'High risk trees',
      //     id: 3,
      //   },
      //   {
      //     title: 'Medium risk trees',
      //     id: 4,
      //   },
      //   {
      //     title: 'Low risk trees',
      //     id: 5,
      //   },
      // ],
    },
    {
      name: "nutrient",
      displayName: "Nutrient+",
      desc:
        "Sophisticated Nitrogen & Phosphorous prediction models that estimate tree or area level nutrient quantities by leveraging multi-spectral images. The predicted N & P values can be used to optimize fertilization & improve yield.",
      img: nutrientImg,
      detailed_desc:
        "From early growth stages, crops require adequate nutrients to grow well and produce good yield. Ceres Nutrient+ module leverages multi-spectral images to predict Nitrogen, Phosphorus, Chlorophyll, Biomass & Vigour levels down to individual trees to improve effectiveness of fertigation operations and save costs from over-fertilization.",
      before_img: nutrientBeforeImg,
      after_img: nutrientafterImg,
      before_section: nutrientsecbeforeImg,
      after_section: nutrientsecafterImg,
      processed_img: nutrientSugarCaneImg,
      processed_img_text: "Nitrogen deficiency level for sugarcane crop",
      case_study:
        "Minimize fertilizer cost, recommendation on fertilizer type, & quantity.",
      key_outputs: [
        {
          top: "Maximise",
          benefit: "Yield",
          bottom: "Accuracy",
          class: "col-md-4",
        },
        {
          top: "Reduce",
          benefit: "Fertilizer",
          bottom: "Cost",
          class: "col-md-4",
        },
        {
          top: "Accurate",
          benefit: "Nutrient",
          bottom: "Prediction",
          class: "col-md-4",
        },
      ],
      // legends: [
      //   {
      //     title: 'High risk trees',
      //     id: 3,
      //   },
      //   {
      //     title: 'Medium risk trees',
      //     id: 4,
      //   },
      //   {
      //     title: 'Low risk trees',
      //     id: 5,
      //   },
      // ],
    },

    {
      name: "smartgrader",
      displayName: "SmartGrader",
      desc:
        "An Artificial Intelligence (AI) android application that automates the detection, counting and grading of oil palm fresh fruit bunches (FFB) by leveraging images taken through a handphone. This android app helps to reinforce proper harvesting SOP and increase yield.",
      img: smartGraderImg,
      detailed_desc:
        "SmartGrader automates the counting & grading of oil palm fresh fruit bunches (FFB). Ceres SmartGrader module uses standard camera resolution images to count and grade FFBs, and can be deployed to mobile devices and used in the field without real-time data connectivity. By analysing the production & quality of harvested FFBs, you can get insights into the quality of the harvesting activities. These insights can help you to fine tune the right time for harvesting and also trace underperforming harvesters.",
      hide_before_after: true,
      processed_img: smartGraderImgProcessed,
      processed_img_text: "",
      case_study:
        "SmartGrader identified sub-optimal harvesting practices that have impacted yield by up to 5%",
      key_outputs: [
        {
          top: "Counting",
          benefit: "100%",
          bottom: "Accuracy",
          class: "col-md-6 col-lg-3",
        },
        {
          top: "Grading",
          benefit: ">95%",
          bottom: "Accuracy",
          class: "col-md-6 col-lg-3",
        },
        {
          top: "Data Entry Time",
          benefit: ">80%",
          bottom: "Reduction",
          class: "col-md-6 col-lg-3",
        },
        {
          top: "Yield & Quality",
          benefit: "5 to 10%",
          bottom: "Improvement",
          class: "col-md-6 col-lg-3",
        },
      ],

      legends: [
        {
          title: "Ripe",
          id: 6,
        },
        {
          title: "Over ripe",
          id: 7,
        },
        {
          title: "Unripe",
          id: 8,
        },
        {
          title: "Under ripe",
          id: 9,
        },
      ],
    },
    // {
    //   name: 'trade',
    //   displayName: 'Trade+',
    //   desc:
    //     'Ceres Trade+ module uses low cost multi-spectral satellite images to scan for crop presence and maturity across countries, providing accurate data for hectarage, age and yield. This provides traders with an advantage in the commodity market.',
    //   img: tradeImg,
    //   detailed_desc:
    //     'Ceres Trade+ module uses low cost multi-spectral satellite images to scan for crop presence and maturity across countries, providing accurate data for hectarage, age and yield. This provides traders with an advantage in the commodity market.',
    //   before_img: tradeBeforeImg,
    //   after_img: tradeafterImg,
    //   before_section: tradesecbeforeImg,
    //   after_section: tradesecafterImg,
    //   case_study:
    //     'An initial scan of North Sumatra, Indonesia reveals that 54% of the land area is covered with palm, of which smallholdings area is almost twice as big as large plantations.',
    //   key_outputs: [
    //     {
    //       top: 'Age Detection',
    //       benefit: '>90%',
    //       bottom: 'Accuracy',
    //       class: 'col-md-4',
    //     },
    //     {
    //       top: 'Hectarage',
    //       benefit: '>92%',
    //       bottom: 'Accuracy',
    //       class: 'col-md-4',
    //     },
    //     {
    //       top: 'Yield',
    //       benefit: '>95%',
    //       bottom: 'Accuracy',
    //       class: 'col-md-4',
    //     },
    //   ],
    //   legends: [
    //     {
    //       title: 'Smallholder palm area',
    //       id: 10,
    //     },
    //     {
    //       title: 'Large plantation palm area',
    //       id: 11,
    //     },
    //   ],
    // },
  ],
};
